import { Flex } from "antd";
import SavedTemplatesSelect from "../../pages/outbound/SavedTemplatesSelect";
import EmojiWrapper from "../custom/EmojiWrapper";
import MediaUploadIconWrapper from "../custom/MediaUploadIconWrapper";

function MessagingAreaHeader(props) {
  const { handleMessage, source, fileList, setFileList, integrationId, chatView, iconsSize, hiddenItems = [] } = props;

  return (
    <Flex justify="center" align="center" className={`${source === "campaign" || source === "add-template" ? "hs-min-h-50-px" : "h-100"}`}>
      <Flex justify={"space-between"} align={"center"} className={`hs-w-98 hs-max-h-50-px rounded-top ${chatView ? "px-1" : "px-3"}`}>
        <Flex align="center" gap={chatView ? "small" : "middle"}>
          <MediaUploadIconWrapper
            source={source}
            showMMS
            fileList={fileList}
            setFileList={setFileList}
            integId={integrationId}
            iconsSize={iconsSize}
          />
          <EmojiWrapper handleEmoji={handleMessage} iconsSize={iconsSize} chatView={chatView} />
        </Flex>
        <div>{!hiddenItems.includes("select-template") && <SavedTemplatesSelect {...props} />}</div>
      </Flex>
    </Flex>
  );
}

export default MessagingAreaHeader;
