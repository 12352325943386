import React, { useEffect, useRef, useState } from "react";

function EmojiWrapper({ handleEmoji, iconsSize = "large", chatView }) {
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
  const emojiPickerContainerRef = useRef(null);
  const [emojiPickerData, setEmojiPickerData] = useState();
  const icon = <div className={`${iconsSize === "small" ? "hs-scale-0-8" : ""} actionIconsSprite emojiIcon`} />;

  useEffect(() => {
    if (isEmojiPickerOpen) {
      fetch("https://cdn.jsdelivr.net/npm/@emoji-mart/data") // This cdn could be replaced by hosting our own cdn
        .then((res) =>
          res.json().then(async (responseJson) => {
            setEmojiPickerData(responseJson);
          })
        );
    }
  }, [isEmojiPickerOpen]);

  useEffect(() => {
    if (emojiPickerData) {
      createPicker();
    }
  }, [emojiPickerData]);

  const createPicker = () => {
    import("emoji-mart").then(
      ({ Picker }) =>
        new Picker({
          data: emojiPickerData,
          open: isEmojiPickerOpen,
          parent: emojiPickerContainerRef.current,
          theme: "light",
          onEmojiSelect: handleEmoji,
          onClickOutside: () => setIsEmojiPickerOpen(false),
          perLine: chatView ? 6 : 9,
          previewPosition: "none",
          maxFrequentRows: 2,
        })
    );
  };

  return (
    <>
      <div onClick={() => setIsEmojiPickerOpen((prev) => !prev)}>{icon}</div>
      <div className="position-relative">
        {isEmojiPickerOpen && <div className="position-absolute bottom-0 customSelectPopup" ref={emojiPickerContainerRef} />}
      </div>
    </>
  );
}

export default EmojiWrapper;
