import {
  CheckCircleOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
  ReloadOutlined,
  StarFilled,
  StarOutlined,
} from "@ant-design/icons";
import { Button, Col, Divider, Empty, Form, Image, Input, Modal, Row, Select, Space, Spin, Statistic, Tooltip, Typography } from "antd";
import dayjs from "dayjs";
import Pusher from "pusher-js";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../assets/css/commonVessels.css";
import Loading from "../../assets/gif/Loading.gif";
import editIcon from "../../assets/icons/editIcon.svg";
import { changeModalState, Chargebee_CHARGE_NAME, urlParams } from "../../constants/AppConstants";
import { ProviderCommonObj, ProviderExtendedCommonObj } from "../../constants/ProviderConstants";
import ZohoCRM from "../../controller/ZohoCRMController";
import useConfigurePhone from "../../hooks/useConfigurePhone";
import useHttp from "../../hooks/useHttp";
import useLicense from "../../hooks/useLicense";
import useNumbers from "../../hooks/useNumbers";
import useTemplates from "../../hooks/useTemplates";
import { AppContext } from "./../../context/AppContext";
import { successNotification } from "./../utils/CommonNotifications";
import { RegisterChargebee } from "./ChargebeeRegistration";
import { failureNotification } from "./CommonNotifications";
import { getHSFormattedDateString } from "./dateUtils";
import HSInput from "../../components/custom/input/input/Input";
const { Text } = Typography;

export const CommonLoading = () => {
  return (
    <div className="container-loading">
      <img
        src={Loading}
        alt="loading"
        style={{
          height: 150,
          width: 200,
          mixBlendMode: "darken",
          pointerEvents: "none",
        }}
      />
    </div>
  );
};

export const CommonSpinLoading = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "61vh", // Full viewport height
      }}
    >
      <LoadingOutlined
        spin
        style={{
          fontSize: 24,
          color: "#605BFF",
        }}
      />
    </div>
  );
};

export const LicenseInvalidModal = () => {
  const [appProperties] = useContext(AppContext);
  const isModalVisible = true;
  const [open, setOpen] = useState(false);
  const { fetchStripeURL } = useLicense();
  const urlParams = new URLSearchParams(window.location.search);
  const purchased = urlParams.get("purchased");

  const handleManageSubscription = (e) => {
    changeModalState(false);
    if (appProperties.licenseHandledBy === "STRIPE") {
      fetchStripeURL(appProperties).then(function (stripeLink) {
        window.open(stripeLink.url, "_blank");
      });
    } else {
      appProperties.controller.pricingLink(appProperties).then(function (resp) {
        window.open(resp, "_blank");
      });
    }
  };

  const getLicenseDetails = () => {
    window.location.reload();
  };

  return (
    <>
      <div>
        <Modal width={400} className="activateLicenseModal" open={isModalVisible} footer={null} closable={false} centered>
          {purchased && (
            <Row className="hs-fw-500">
              <span className="actionIconsSprite refresh-icon-new refreshIconHover " onClick={getLicenseDetails}></span> Please refresh the page
              to stay updated
            </Row>
          )}
          <Row>
            <Col span={20}>
              <div className="d-flex align-items-left justify-content-left mt-3 mb-3">
                <div className="staticIconsSprite licenseIcon"></div>
              </div>
            </Col>
          </Row>
          <div style={{ fontSize: "16px", fontWeight: 600 }} className="mb-1 me-3">
            Get access to stay connected with client replies.
          </div>
          <div className="pb-3">License starts from $9 per user</div>
          <Row>
            <Col span={10} className="activateExtendBtn">
              <Button
                onClick={() => setOpen(true)}
                id="extendTrail"
                className="fs-6 extendTrail w-100"
                size="large"
                style={{ backgroundColor: "#F5F5F5", border: "none" }}
              >
                <span style={{ fontSize: "14px" }}>Extend Trial</span>
              </Button>
            </Col>
            <Col span={10} className="activateLicenseBtn ms-4">
              <Button onClick={handleManageSubscription} className="fs-6 activateLicensedBtn" size="large">
                <span style={{ fontSize: "14px" }}>Get Access</span>
              </Button>
            </Col>
          </Row>
        </Modal>

        {open && (
          <Modal title=" " centered open={open} onOk={() => setOpen(false)} onCancel={() => setOpen(false)} width={1000} footer={null}>
            <div>
              <iframe
                className="iframe-popup"
                src={`https://oapps.zohobookings.com/portal-embed#/share-and-extend?Name=${appProperties?.userName}&Email=${appProperties?.currentUserEmail}`}
                frameBorder="0"
                allowFullScreen
                title="Extend Trial"
              />
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};

export const InvalidModal = (props) => {
  let title = "";
  let modalContent = "";
  let modalButton = "";
  let link = "";

  const { authorizeModal } = props;
  const [showAuthorizeModal, setShowAuthorizeModal] = useState(authorizeModal ?? false);
  const location = useLocation();
  const [appProperties] = useContext(AppContext);
  let authObj = appProperties.authorizeObj;
  let leftAuth = authObj?.left?.auth?.authorized;
  let rightAuth = authObj?.right?.auth?.authorized;
  if (!leftAuth || !rightAuth) {
    title = appProperties?.authTitle;
    modalContent = appProperties?.authContent;
    modalButton = appProperties?.authButton;
    link = `/channels/textsms/${authObj?.right?.service_name}`;
    appProperties?.controller?.getUrlParamsForRedirection(true, appProperties).then(function (redirectionUrlFromApp) {
      if (redirectionUrlFromApp) {
        link += "?" + redirectionUrlFromApp;
      } else {
        link += location.search;
      }
    });
  }
  const navigate = useNavigate();

  function DoAuthorization() {
    if (!leftAuth) {
      const leftAuthWindow = window.open(appProperties.authLink, "Popup", "width=700,height=700,left=350,top=100");
      const receiveMessage = (event) => {
        if (event !== undefined && event.data !== undefined) {
          console.log("addEventListener receiveMessage event>>>>>>>>>>>>>>>>", event);
          console.log("addEventListener receiveMessage event.data>>>>>>>>>>>>>>>>", event.data);
          let data = event.data;
          if (typeof event.data === "string") {
            data = JSON.parse?.(event.data);
          }
          if (data !== undefined && data?.data?.integId !== undefined) {
            console.log("::::::::::::::::    SUCESSSS      :::::::::::::::s");
            navigate("/channels" + location.search);
          } else if (data !== undefined && data?.data?.osyncId !== undefined) {
            setShowAuthorizeModal(false);
          }
        }
      };
      try {
        window.addEventListener("message", receiveMessage);
        return () => {
          console.log("addEventListener  receiveMessage event registration>>>>>>>>>>>>>>>>");
          leftAuthWindow.close();
          window.removeEventListener("message", receiveMessage);
        };
      } catch (error) {
        console.log("addEventListener  receiveMessage error>>>>>>>>>>>>>>>>", error);
      }
    } else {
      window.open(link);
    }
  }
  return (
    <>
      <Modal width={700} className="Modal" open={showAuthorizeModal} footer={null} closable={false} centered>
        <Row>
          <Col xs={5} md={4} lg={4} xl={4}>
            <ClockCircleOutlined className="clockIconInModal" />
          </Col>
          <Col xs={18} md={12} lg={12} xl={12} className="activateAuthorizeModal">
            <p className="modalTitle text-dark">{title}</p>
            <p className="modalDescription text-secondary">{modalContent}</p>
          </Col>
          <Col xs={3} md={2} lg={2} xl={2}></Col>
          <Col xs={18} md={12} lg={12} xl={12} className="ModalBtn">
            <Button onClick={DoAuthorization} target="_blank" className="fs-6 activateBtn" type="primary" size="middle">
              <span style={{ "font-size": "1rem;" }}>{modalButton}</span>
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export const ErrorModal = (props) => {
  return (
    <>
      <Modal width={700} className="errorMessageModal" open={props.showError} footer={null} closable={false} centered>
        <Row>
          <Col xs={3} md={2} lg={2} xl={2}>
            <ExclamationCircleOutlined className="exclamatoryIcon" />
          </Col>
          <Col xs={18} md={20} lg={20} xl={20} className="errorMessageModal">
            <p className="errorModalTitle">{props.errorMessage}</p>
            <p className="errorModalDescription">{props.errorDescription}</p>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export function NoticeModal({ show, handleModalCancel, handleModalConfirm, cancel }) {
  return (
    <Modal
      centered
      width={400}
      title="Important Notice"
      open={show}
      className="noticeModal"
      onCancel={cancel}
      footer={[
        <Button key="back" danger ghost className="noticeModalButton" onClick={handleModalCancel}>
          cancel
        </Button>,
        <Button key="submit" type="primary" className="noticeModalButton" onClick={handleModalConfirm}>
          confirm
        </Button>,
      ]}
    >
      Altering the SID might impact actions configured earlier. Nevertheless ,I remain interested in moving forward with the override.
    </Modal>
  );
}

export function EditMessageServiceId({ msgSidList, messageServiceId, friendlyName, phoneNumber, integId }) {
  const [hover, setHover] = useState(false);
  const [showSelect, setShowSelect] = useState(false);
  const [getMsgValue, setMsgValue] = useState(messageServiceId);
  const [messageServiceValue, setMessageServiceValue] = useState(null);
  const [showNotice, setShowNotice] = useState(false);
  const [finalMessageServiceId, setFinalMessageServiceId] = useState(null);
  const [msgSidLists, setMsgSidLists] = useState([]);
  const [appProperties] = useContext(AppContext);

  const { fetchData } = useHttp();

  useEffect(() => {
    if (msgSidList?.length < 1) {
      fetchData(`omessage/${integId}/numbers`, "GET", null, appProperties)
        .then((response) => {
          const responseData = JSON.parse(response);
          if (responseData?.data?.messagingServices) {
            let getMsgServicesLists = responseData.data.messagingServices;
            const msgSidsList = getMsgServicesLists.map((service) => ({
              friendlyName: service.friendlyName,
              sid: service.sid,
            }));
            setMsgSidLists(msgSidsList);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setMsgSidLists(msgSidList);
    }
  }, [appProperties, fetchData, integId, msgSidList]);

  function handleServiceChange(value) {
    setMessageServiceValue(value);
  }
  function handleMsgService() {
    if (messageServiceValue === getMsgValue || messageServiceValue === finalMessageServiceId) {
      setHover(false);
      setShowSelect(false);
    } else if (messageServiceValue !== "") {
      setShowNotice(true);
    }
  }

  function editModalConfirm() {
    setShowNotice(false);

    const appData = {
      phoneNumber: phoneNumber,
      messageServiceId: getMsgValue,
      friendlyName: friendlyName,
    };
    fetchData("omessage/" + integId + "/updatePhone?associatedSid=" + messageServiceValue, "PUT", appData, appProperties).then(function (
      response
    ) {
      response = JSON.parse(response);
      if (response.data.data !== undefined) {
        setFinalMessageServiceId(response.data.data.messageServiceId);
        setMsgValue(response.data.data.messageServiceId);
        setShowSelect(false);
        setHover(false);
      }
      if (response.data.errorMessage !== undefined) {
        setFinalMessageServiceId(messageServiceId);
        failureNotification("ERROR", response.data.errorMessage);
        setShowSelect(false);
        setHover(false);
      }
    });
  }
  return (
    <>
      {hover === true ? (
        <>
          {showSelect === false ? (
            <div
              onMouseOut={() => {
                setHover(false);
              }}
              onClick={() => {
                setShowSelect(true);
              }}
            >
              <img
                src={editIcon}
                alt="editIcon"
                style={{
                  height: "20px",
                  width: "20px",
                  cursor: "pointer",
                }}
              />
            </div>
          ) : (
            <>
              <div>
                <Space>
                  <Select
                    className="hs-bg-off-white focus-border w-100"
                    suffixIcon={<div className="actionIconsSprite fromNumberDropDownIcon" />}
                    bordered={null}
                    style={{ width: "10rem" }}
                    placeholder="choose Messaging Service"
                    onSelect={handleServiceChange}
                    // onMouseOut={()=>{setHover(false)}}
                  >
                    {msgSidLists?.map((value, index) => (
                      <Select.Option key={index} value={value.sid}>
                        {value.friendlyName + "(" + value.sid + ")"}
                      </Select.Option>
                    ))}
                  </Select>
                  <NoticeModal
                    show={showNotice}
                    cancel={() => {
                      setShowNotice(false);
                    }}
                    handleModalCancel={() => {
                      setShowNotice(false);
                    }}
                    handleModalConfirm={editModalConfirm}
                  />
                  <CheckCircleOutlined className="checkMsgIcon" onClick={handleMsgService} />
                  <CloseCircleOutlined
                    className="closeMsgIcon"
                    onClick={() => {
                      setShowSelect(false);
                    }}
                  />
                </Space>
              </div>
            </>
          )}
        </>
      ) : (
        <div
          onMouseOver={() => {
            setHover(true);
          }}
          onMouseOut={() => {
            setHover(false);
          }}
        >
          {finalMessageServiceId || messageServiceId}
        </div>
      )}
    </>
  );
}

export function RefreshLicenseDetails(props) {
  const { usersTabRefreshBtn } = props;
  const [appProperties, setAppProperties] = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const { fetchData } = useHttp();
  const getLicenseDetails = () => {
    setLoading(true);
    fetchData(`omni/license?leftServiceId=${appProperties.leftServiceId}&osyncId=${appProperties.osyncId}`, "GET", null, appProperties)
      .then((response) => {
        response = JSON.parse(response);
        var license = response.data;
        appProperties.licenseObj.licenseDetails = license;
        setAppProperties((prev) => ({ ...prev, licenseObj: license }));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  return (
    <>
      <Button
        className="actionIconsSprite refresh-icon refreshIconHover p-1"
        loading={loading}
        onClick={getLicenseDetails}
        style={{ border: "none", marginTop: usersTabRefreshBtn ? "5px" : "2px", marginRight: usersTabRefreshBtn ? "5px" : "0px" }}
      ></Button>
    </>
  );
}

export const CommonLoadingV2 = () => {
  return (
    <div className="loading d-flex justify-content-center align-items-center">
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export const NoChannelPresent = ({ newTab, baseUrl = "/channels", message = "No channels Connected", buttonText = "Add Channels" }) => {
  const [appProperties] = useContext(AppContext);
  const [channelRedirectUrl, setChannelRedirectUrl] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  urlParams.delete("sideBar");
  urlParams.delete("topBar");

  useEffect(() => {
    if (appProperties?.controller?.getUrlParamsForRedirection) {
      appProperties.controller.getUrlParamsForRedirection(true, appProperties).then(function (respUrl) {
        setChannelRedirectUrl(respUrl);
      });
    }
  }, [appProperties?.controller]);

  return (
    <div className="d-flex flex-column m-auto w-25 align-items-center">
      <div style={{ marginTop: "30%" }} className="staticIconsSprite chatIcon-sprite"></div>
      <span
        className="m-2"
        style={{
          fontSize: newTab ? 12 : 16,
          color: "#818094",
          whiteSpace: "nowrap",
        }}
      >
        {message}
      </span>
      <Button
        className="rounded m-2 addChannel-btn"
        type=""
        onClick={() => {
          if (newTab) {
            window.open(baseUrl + "?" + channelRedirectUrl);
          } else {
            navigate(baseUrl + location.search);
          }
        }}
        style={{ height: 48, width: 220 }}
      >
        {buttonText}
      </Button>
    </div>
  );
};
export const LowCreditsWarning = () => {
  const [appProperties] = useContext(AppContext);
  const [BalanceCredits, setBalanceCredits] = useState(null);
  useEffect(() => {
    if (appProperties.licenseObj != null) {
      let licenseInfo = appProperties.licenseObj.licenseDetails;
      if (licenseInfo !== null && licenseInfo !== undefined) {
        setBalanceCredits(licenseInfo?.creditsPurchased - licenseInfo?.creditsConsumed);
      }
    }
  }, [appProperties.licenseObj]);
  return (
    <>
      {BalanceCredits < 10 && appProperties?.licenseObj?.licenseDetails?.creditsPurchased > 0 ? (
        <div
          className="p-1 m-3 rounded d-flex align-items-center"
          style={{
            border: "1px solid #F44336",
            backgroundColor: "#FFEBEE",
          }}
        >
          <ExclamationCircleOutlined
            className="pe-2"
            style={{
              color: "#F44336",
              fontSize: 20,
            }}
          />
          Low balance in your wallet, top up for uninterrupted service
        </div>
      ) : null}
    </>
  );
};
export const FromNumberComponent = React.memo(
  ({
    fromNumber,
    handleFromNumberChange,
    savedNumbersList,
    setSavedNumbersList,
    defaultSelectFirstOption,
    tabIndex,
    customClasses,
    disabled,
    variant,
    ignoredServices,
    chatView,
  }) => {
    const { fetchData } = useHttp();
    const [appProperties] = useContext(AppContext);
    const { getAllSavedNumbers } = useNumbers(appProperties);
    const [fromNumberSelectLoading, setFromNumberSelectLoading] = useState(true);
    console.log("appProps ::: ", appProperties);

    const mapPhoneAndMessagingServiceObjToSavedNumber = (phoneList, msgObjList) => {
      if (msgObjList.length > 0) {
        phoneList = [
          ...(phoneList || []),
          {
            label: (
              <Divider plain style={{ margin: 0, fontSize: "12px" }} orientation="left">
                Message Service ID
              </Divider>
            ),
            value: "divider",
            disabled: true,
          },
          ...msgObjList,
        ];
      }
      if (phoneList !== undefined && phoneList.length > 0) {
        if (defaultSelectFirstOption) {
          const defaultPhoneNumber = phoneList.find((obj) => obj?.defaultNumber === 1)?.phoneNumber || phoneList[0]?.phoneNumber;
          handleFromNumberChange(defaultPhoneNumber);
        }
        phoneList = mapDataToOption(phoneList, appProperties, setSavedNumbersList);
      } else {
        phoneList = [];
      }
      setSavedNumbersList(phoneList);
    };

    const checkIsServiceIsNotIgnored = (serviceName) => {
      return !ignoredServices?.includes(serviceName);
    };

    const removeIgnoredServicesFromPhoneList = (savedNumbers) => {
      if (ignoredServices) {
        return savedNumbers?.filter((number) => checkIsServiceIsNotIgnored(number?.serviceName));
      }
      return savedNumbers;
    };

    useEffect(() => {
      const fetchApi = async () => {
        var phoneList = [];
        var msgObjList = [];
        if (appProperties.apps) {
          const savedNumbers = appProperties.savedNumbers;
          if (savedNumbers && savedNumbers?.length > 0) {
            savedNumbers?.forEach((item) => {
              if (checkIsServiceIsNotIgnored(item.serviceName)) {
                if (item?.isMessagingService === "true") {
                  msgObjList.push(item);
                } else {
                  phoneList.push(item);
                }
              }
            });
            console.log("phoneList,,,,", phoneList);
          } else {
            try {
              const response = await getAllSavedNumbers();
              phoneList = removeIgnoredServicesFromPhoneList(response.data);
              msgObjList = removeIgnoredServicesFromPhoneList(response.messagingServices);
            } catch (error) {
              console.log("error >> ", error);
            }
          }

          if (phoneList.length === 0) {
            handleFromNumberChange(null);
          }
          setFromNumberSelectLoading(false);
          mapPhoneAndMessagingServiceObjToSavedNumber(phoneList, msgObjList);
        }
      };
      if (appProperties?.serviceInstalled) {
        fetchApi();
      }
    }, [appProperties.apps, fetchData, savedNumbersList?.length, setSavedNumbersList, defaultSelectFirstOption]);

    useEffect(() => {
      if (appProperties) {
        let numberObj = savedNumbersList?.find((obj) => obj?.phoneNumber === fromNumber);
        let rightServiceName = appProperties?.installedApps?.find((obj) => obj?.integProps?.integId === numberObj?.integId)?.right?.service_name;
        let assignerObj1 = Object.assign(ProviderCommonObj, ProviderExtendedCommonObj[rightServiceName]);
        appProperties.providerCommonObj = assignerObj1;
        appProperties.rightServiceName = rightServiceName;
      }
    }, [fromNumber, appProperties]);

    return (
      <Select
        disabled={fromNumber ? disabled : false}
        className={`hs-fs-14 rounded hs-input-box ${chatView ? "" : "hs-focus-border"} hs-placeholder-text ${
          customClasses || ""
        } select-variant-${variant}`}
        id="fromPhoneNumber"
        value={fromNumber || null}
        options={[
          ...(mapDataToOption(savedNumbersList, appProperties, setSavedNumbersList) || []),
          {
            value: "add::::phone",
            label: (
              <div type="" className="d-flex align-items-center addPhone-sendsms">
                <PlusOutlined style={{ verticalAlign: "middle" }} />
                <span className="p-1 ps-2" style={{ color: "#374151" }}>
                  Add Phone
                </span>
              </div>
            ),
          },
        ]}
        placeholder="Select From number"
        autoFocus
        style={{ width: "100%" }}
        bordered={null}
        tabIndex={tabIndex}
        suffixIcon={
          fromNumberSelectLoading ? (
            <div className="actionIconsSprite dropDownLoading" />
          ) : (
            <div className="actionIconsSprite fromNumberDropDownIcon" />
          )
        }
        onSelect={(v) => {
          if (v === "add::::phone") {
            if (appProperties?.osyncId) {
              appProperties?.controller?.getUrlParamsForRedirection(true, appProperties).then(function (redirectionUrl) {
                window.open("/channels/textsms?" + redirectionUrl, "_blank");
              });
            }
          } else {
            handleFromNumberChange(v);
          }
        }}
      />
    );
  }
);

export const SendSMSToNumberComponent = React.memo(
  ({
    toNumber,
    setToNumber,
    savedNumbersList,
    setSavedNumbersList,
    defaultSelectFirstOption,
    tabIndex,
    customClasses,
    disabled,
    setPhoneFields,
    phoneFieldConfiguration,
    setPhoneFieldConfiguration,
  }) => {
    const [appProperties, setAppProperties] = useContext(AppContext);
    const { fetchPhoneFieldsWithValues } = useTemplates();
    console.log("appProps ::: ", appProperties);
    const [currentModule, setCurrentModule] = useState("");
    const [recordId, setRecordId] = useState("");
    const [toNumberSelectLoading, setToNumberSelectLoading] = useState(true);
    const [toPhoneNumberList, setToPhoneNumberList] = useState(null);
    const [fieldResponse, setFieldResponse] = useState();
    const [customPhoneFieldConfiguration, setCustomPhoneFieldConfiguration] = useState();
    const { fetchData } = useHttp();
    const phoneNumberRef = useRef(null);
    useEffect(() => {
      let phoneFieldConfig = phoneFieldConfiguration;
      if (!phoneFieldConfiguration?.data?.config?.secondaryPhoneApiName && !phoneFieldConfiguration?.data?.config?.primaryPhoneApiName) {
        phoneFieldConfig = customPhoneFieldConfiguration;
      }
      console.log(toNumber, fieldResponse, "render1111111");

      appProperties?.controller?.getAssociatedObjectType().then(function (associatedModule) {
        if (associatedModule) {
          setCurrentModule(associatedModule);
        }
      });
      appProperties?.controller?.getAssociatedObjectId().then(function (recordId) {
        if (recordId) {
          setRecordId(recordId);
        }
      });
      if (!toNumber && fieldResponse) {
        if (phoneFieldConfig?.data?.config?.secondaryPhoneLookUp || phoneFieldConfig?.data?.config?.primaryPhoneLookUp) {
          console.count("render111");
          appProperties?.controller?.fetchContact(fetchData, appProperties, phoneFieldConfig).then(function (contactNumbersObj) {
            if (contactNumbersObj) {
              const phoneNumbers = contactNumbersObj.map((record) => record.phone).join(",");
              if (phoneNumberRef.current !== "configured") {
                setToNumber(phoneNumbers);
              }
              if (
                !phoneNumbers &&
                !phoneFieldConfiguration?.data?.config?.secondaryPhoneApiName &&
                !phoneFieldConfiguration?.data?.config?.primaryPhoneApiName
              ) {
                console.log("toPhoneNumberList", toPhoneNumberList, toPhoneNumberList?.[0]?.fieldValue);
                let currentModulePhone = toPhoneNumberList?.find((obj) => obj?.fieldValue);
                setToNumber(currentModulePhone?.fieldValue);
              }
            }
          });
        }
      }
    }, [appProperties?.controller, phoneFieldConfiguration, toNumber, fetchData, customPhoneFieldConfiguration, fieldResponse]);

    useEffect(() => {
      if (toNumber) {
        setToNumberSelectLoading(false);
        setAppProperties((prev) => ({
          ...prev,
          phone: toNumber,
        }));
      }
    }, [toNumber]);

    useEffect(() => {
      if (currentModule && recordId && appProperties?.serviceInstalled) {
        fetchPhoneFieldsWithValues(appProperties, currentModule, recordId)
          .then((response) => {
            if (response) {
              setFieldResponse(response);

              const allPhoneFields = response?.fields?.filter((field) => field.fieldType === "phone") || [];
              setToPhoneNumberList(allPhoneFields ?? []);

              let filterPhoneOptionsArray = ConstructFieldsList(response);
              if (filterPhoneOptionsArray) {
                setPhoneFields(filterPhoneOptionsArray);
              }
            }
          })
          .finally(() => {
            setToNumberSelectLoading(false);
          });
      }
    }, [currentModule, recordId, appProperties?.serviceInstalled]);

    useEffect(() => {
      phoneNumberRef.current = "clear";
      const isObjectEmpty = (obj) => Object.keys(obj).length === 0;
      console.log("toPhoneNumberList >>>>>>>shirt>>>>", toPhoneNumberList);
      const getFieldValue = (apiName) => toPhoneNumberList?.find((item) => item?.fieldApiName === apiName)?.fieldValue || null;

      const getPhoneNumber = (lookupApiName, phoneApiName) => {
        console.log("deals  fieldResponse >>>>>>>>>>>>>", fieldResponse);
        console.log("deals  fieldResponse?.lookups?.length >>>>>>>>>>>>>", fieldResponse?.lookups?.length);
        if (lookupApiName && fieldResponse && fieldResponse?.lookups?.length > 0) {
          const phoneObjects = fieldResponse?.lookups
            ?.filter((lookup) => lookup.fieldApiName === lookupApiName) // Filter for organization
            .flatMap(
              (lookup) => lookup.fields.filter((field) => field.fieldType === "phone") // Filter for phone type
            );
          return phoneObjects.find((item) => item.fieldApiName === phoneApiName)?.fieldValue || null;
        } else {
          return getFieldValue(phoneApiName);
        }
      };

      const handlePhoneNumber = () => {
        const { primaryPhoneApiName, secondaryPhoneApiName, primaryPhoneLookUp, secondaryPhoneLookUp } =
          phoneFieldConfiguration?.data?.config || {};

        let phoneNumber = getPhoneNumber(primaryPhoneLookUp, primaryPhoneApiName);
        if (phoneNumber) {
          phoneNumberRef.current = "configured";
        }

        if (!phoneNumber) {
          phoneNumber = getPhoneNumber(secondaryPhoneLookUp, secondaryPhoneApiName);
        }
        if (phoneNumber) {
          setToNumberSelectLoading(false);
          setToNumber(phoneNumber);
        } else {
          handlePhoneNumberDefault();
        }
      };
      const handlePhoneNumberDefault = () => {
        const { primaryPhoneApiName, secondaryPhoneApiName, primaryPhoneLookUp, secondaryPhoneLookUp } = appProperties?.controller || {};
        let phoneNumber = getPhoneNumber(primaryPhoneLookUp, primaryPhoneApiName);
        if (!phoneNumber) {
          phoneNumber = getPhoneNumber(secondaryPhoneLookUp, secondaryPhoneApiName);
        }
        if (phoneNumber) {
          setToNumberSelectLoading(false);
          setToNumber(phoneNumber);
        } else {
          appProperties?.controller?.getDefaultModuleForAGivenModule(currentModule).then(function (defaultModule) {
            let defaultApiName = appProperties?.controller?.primaryPhoneApiName;
            if (!defaultApiName) defaultApiName = toPhoneNumberList?.find((obj) => obj?.fieldValue)?.fieldApiName ?? null;
            phoneNumber = getPhoneNumber(defaultModule?.[0], defaultApiName);
            if (phoneNumber) {
              setToNumber(phoneNumber);
            } else {
              var defaultPhoneConfig = {};
              defaultPhoneConfig.data = {};
              defaultPhoneConfig.data.config = {
                primaryPhoneApiName: appProperties?.controller?.primaryPhoneApiName,
                primaryPhoneLookUp: defaultModule?.[0],
                secondaryPhoneApiName: appProperties?.controller?.primaryPhoneApiName,
                secondaryPhoneLookUp: defaultModule?.[1],
                module: currentModule,
              };
              if (!phoneFieldConfiguration?.data?.config?.secondaryPhoneApiName && !phoneFieldConfiguration?.data?.config?.primaryPhoneApiName) {
                setCustomPhoneFieldConfiguration(defaultPhoneConfig);
              }
            }
          });
        }
      };

      if (fieldResponse && phoneFieldConfiguration && !isObjectEmpty(phoneFieldConfiguration)) {
        handlePhoneNumber();
      } else if (fieldResponse && appProperties?.controller) {
        handlePhoneNumberDefault();
      }
    }, [phoneFieldConfiguration, fieldResponse]);

    return (
      <Select
        disabled={disabled}
        // disabled={toPhoneNumberList && toPhoneNumberList.length > 0 ? disabled : true}
        className={`rounded h-45 ${customClasses || ""}`}
        id="fromPhoneNumber"
        value={toNumber || null}
        // value={toPhoneNumberList && toPhoneNumberList.length > 0 ? toNumber : null || null}
        options={[...(constructRecipientsData(toPhoneNumberList, setToPhoneNumberList) || [])]}
        placeholder={toNumberSelectLoading ? "" : <span className="hs-color-red">No number found</span>}
        autoFocus
        style={{ width: "100%" }}
        bordered={null}
        tabIndex={tabIndex}
        suffixIcon={
          toNumberSelectLoading ? (
            <div className="actionIconsSprite dropDownLoading" />
          ) : (
            <div className="actionIconsSprite fromNumberDropDownIcon" />
          )
        }
        notFoundContent={
          toPhoneNumberList ? (
            <NotFoundContent content="No phone field available." />
          ) : (
            <div
              className="d-flex justify-content-center align-items-center w-100"
              style={{
                height: "100px",
              }}
            >
              <div className="actionIconsSprite dropDownLoading" />
            </div>
          )
        }
        onSelect={(v) => {
          setToNumber(v);
        }}
      />
    );
  }
);

export const SetAsDefaultPhone = (props) => {
  const { phoneObj, item, setSavedNumbers } = props;
  const [appProperties] = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const { fetchData } = useHttp();
  const handleSetDefaultNumber = (e, method) => {
    e.stopPropagation();
    if (method === "add") {
      setLoading(true);
      fetchData(`${item?.integId}/number/default?phoneNumber=${encodeURIComponent(item?.phoneNumber)}`, "PUT", null, appProperties).then(
        function (response) {
          if (response) {
            response = JSON.parse(response);
            setLoading(false);
            var updatedPhoneObj = phoneObj.map((phoneItem) => ({
              ...phoneItem,
              defaultNumber: phoneItem.phoneNumber === response?.data?.phone?.phoneNumber ? 1 : 0,
            }));
            updatedPhoneObj = mapDataToOption(updatedPhoneObj, appProperties, setSavedNumbers);
            setSavedNumbers(updatedPhoneObj);
          }
        }
      );
    } else {
      setLoading(true);
      fetchData(
        `${item?.integId}/number/default?phoneNumber=${encodeURIComponent(item?.phoneNumber)}&action=remove`,
        "PUT",
        null,
        appProperties
      ).then(function (response) {
        if (response) {
          response = JSON.parse(response);
          var updatedPhoneObj = phoneObj.map((phoneItem) => ({
            ...phoneItem,
            defaultNumber: phoneItem.phoneNumber === response?.data?.phone?.phoneNumber ? 0 : 0,
          }));
          updatedPhoneObj = mapDataToOption(updatedPhoneObj, appProperties, setSavedNumbers);
          setLoading(false);
          setSavedNumbers(updatedPhoneObj);
        }
      });
    }
  };
  return (
    <>
      <Button
        loading={loading}
        bordered={null}
        type="link"
        onClick={(e) => {
          handleSetDefaultNumber(e, item?.defaultNumber === 1 ? "remove" : "add");
        }}
        className={item?.defaultNumber === 1 ? "defaultPhoneStarred labelMode" : "defaultPhone labelMode"}
      >
        {item?.defaultNumber === 1 ? (
          <Tooltip title="Set as Default">
            <StarFilled style={{ color: "#FFD66B", fontSize: 20 }} />
          </Tooltip>
        ) : (
          <Tooltip title="Set as Default">
            <StarOutlined style={{ fontSize: 20 }} />
          </Tooltip>
        )}
      </Button>
    </>
  );
};
export const mapDataToOption = (updatedPhoneObj, appProperties, setSavedNumbers) => {
  if (updatedPhoneObj?.length > 0) {
    updatedPhoneObj = updatedPhoneObj.map((item) => {
      let realPhoneNumber = item.phoneNumber;

      if (realPhoneNumber?.includes(":::")) {
        realPhoneNumber = realPhoneNumber?.split(":::")[1];
      }

      return {
        ...item,
        value: item.phoneNumber,
        label: item?.phoneNumber ? (
          <Tooltip title={realPhoneNumber}>
            <div className="addPhone-sendsms">
              <div className="d-flex align-items-center addPhone-sendsms">
                <div
                  className={
                    "numberlist-sprites numberlist-" +
                    appProperties?.installedApps?.find((obj) => obj?.integProps?.integId === item.integId)?.right?.service_name
                  }
                ></div>
                <span>
                  {(() => {
                    let labelName = (item.friendlyName || "") + `(${realPhoneNumber})`;
                    if (labelName?.length > 30) {
                      return labelName.slice(0, 30) + "...";
                    } else {
                      return labelName;
                    }
                  })()}
                </span>

                <SetAsDefaultPhone setSavedNumbers={setSavedNumbers} phoneObj={updatedPhoneObj} item={item} />
              </div>
            </div>
          </Tooltip>
        ) : (
          item?.label
        ),
      };
    });
  }
  return updatedPhoneObj;
};

export const constructRecipientsData = (toPhoneNumberList, setToPhoneNumberList) => {
  if (toPhoneNumberList?.length > 0) {
    toPhoneNumberList = toPhoneNumberList.map((item) => {
      return {
        ...item,
        value: item.fieldValue,
        label: item?.fieldValue ? (
          <Tooltip title={item?.fieldValue}>
            <div className="addPhone-sendsms">
              <div className="d-flex align-items-center addPhone-sendsms">
                <span>
                  {(() => {
                    let labelName = (item.fieldName || "") + ` (${item.fieldValue})`;
                    if (labelName?.length > 30) {
                      return labelName.slice(0, 30) + "...";
                    } else {
                      return labelName;
                    }
                  })()}
                </span>

                {/* <SetAsDefaultPhone item={item} /> */}
              </div>
            </div>
          </Tooltip>
        ) : (
          item?.fieldName
        ),
      };
    });
  }
  return toPhoneNumberList;
};

export const initPusher = (fetchData, appProperties) => {
  return new Promise((resolve, reject) => {
    try {
      let pusherRef = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
        cluster: process.env.REACT_APP_PUSHER_CLUSTER,
        encrypted: true,
        forceTLS: true,
        channelAuthorization: {
          customHandler: async (params, authorize) => {
            try {
              const response = await fetchData(
                `pusher/auth?socketId=${params.socketId}&channelName=${params.channelName}`,
                "post",
                null,
                appProperties
              );
              const responseJson = JSON.parse(response);
              authorize(null, responseJson.data);
            } catch (error) {
              reject(new Error("Error authorizing Pusher channel: " + error.message));
            }
          },
        },
      });

      let channelKey = appProperties?.osyncId;
      let channelRef = null;
      if (channelKey) {
        channelRef = pusherRef.subscribe(`private-${channelKey}`);
      }

      function disposePusher() {
        channelRef?.unbind_all();
        pusherRef?.unsubscribe(appProperties?.osyncId);
        pusherRef?.disconnect();
      }

      if (channelRef) {
        resolve({
          channel: channelRef,
          pusher: pusherRef,
          dispose: disposePusher,
        });
      } else {
        reject(new Error("Error initializing Pusher: Channel reference is undefined"));
      }
    } catch (error) {
      reject(new Error("Error initializing Pusher: " + error.message));
    }
  });
};
export const formatDate = (date) => {
  return dayjs(date).format("DD MMM, YYYY h:mm a");
};

export const CountUpStatistic = ({ title, finalCount, duration }) => {
  const [count, setCount] = useState(0);
  useEffect(() => {
    let start = 0;
    const increment = finalCount / ((duration * 1000) / 70);

    const interval = setInterval(() => {
      start += increment;
      if (start >= finalCount) {
        start = finalCount;
        clearInterval(interval);
      }
      setCount(Math.floor(start));
    }, 50);

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [finalCount, duration]);

  return <Statistic className="statistics-data" title={title} value={isNaN(count) ? 0 : count} />;
};

export const BuyFunds = () => {
  const [appProperties, setAppProperties] = useContext(AppContext);
  function registerAgain() {
    RegisterChargebee("Omni", appProperties, setAppProperties);
  }
  return (
    <Button data-cb-type="checkout" data-cb-item-0={Chargebee_CHARGE_NAME} data-cb-item-0-quantity="1" type="link" onMouseEnter={registerAgain}>
      Add Funds
    </Button>
  );
};

export const ConfirmAlertModal = React.memo((props) => {
  const { onCancel, open, onConfirm, centered, alertIcon, titleContent, modalContent, buttonConfirm, buttonClassName = "" } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const handleModalConfirm = async () => {
    if (onConfirm) {
      setConfirmLoading(true);
      await onConfirm();
      setConfirmLoading(false);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onCancel={onCancel}
        centered={centered}
        width={400}
        className="campaignOverviewEdit"
        footer={
          <div className="d-flex align-items-center justify-content-between mt-3 m-2">
            <Button className="w-100 me-2 h-45" style={{ color: "#605BFF" }} onClick={onCancel}>
              Cancel
            </Button>
            <Button
              className={`w-100 ms-2 h-45 ${buttonClassName}`}
              type="primary"
              style={{ background: "#605BFF" }}
              onClick={handleModalConfirm}
              loading={confirmLoading}
            >
              {buttonConfirm}
            </Button>
          </div>
        }
      >
        <Row>
          <Col span={22}>
            <div className="d-flex align-items-center p-1  ps-0">{alertIcon}</div>
          </Col>
          <Col span={2} onClick={onCancel}>
            <div className="m-2 p-1 pt-2 pe-2">
              <div className="actionIconsSprite closeIconBig me-4"></div>
            </div>
          </Col>
        </Row>
        <div style={{ fontSize: "16px", fontWeight: 600 }} className="mb-1 mt-2">
          {titleContent}
        </div>
        <div className="pb-3">{modalContent}</div>
      </Modal>
    </>
  );
});

export const ActivityDetailsModal = React.memo((props) => {
  const { data, handleClose, open } = props ?? {};
  let { createdBy: createdUser, createdOn, modifiedBy } = data ?? {};
  createdUser = {
    ...createdUser,
    date: createdOn,
    create: true,
  };
  return (
    <Modal
      centered
      title={<div className="hs-fs-18">Activity</div>}
      cancelText={null}
      footer={<></>}
      className="activityDetailsModal"
      maskClosable={true}
      closeIcon={<div onClick={handleClose} className="actionIconsSprite closeIconSmall" />}
      mask={true}
      onCancel={handleClose}
      open={open}
    >
      <div className="container pb-2">
        <p>Who were the users involved in creating and editing this campaign</p>
        <div className="justify-content-center activityDetailList">
          {createdUser && <ActivityDetailItem user={createdUser} />}
          {modifiedBy &&
            modifiedBy?.map((modifiedByUser) => {
              return (
                <>
                  <hr className="hs-hr" />
                  <ActivityDetailItem user={modifiedByUser} />
                </>
              );
            })}
        </div>
      </div>
    </Modal>
  );
});

const ActivityDetailItem = (props) => {
  const { user } = props;
  const formattedDate = getHSFormattedDateString(user?.date);
  return (
    <div className="row hs-w-90 mt-3 d-flex align-items-center ms-1">
      <div className="col-3">
        {user?.iconUrl ? (
          <img alt="sender_url" src={user?.iconUrl} className="avatar-big-round" />
        ) : user?.name ? (
          <div className="avatar-big-round fw-bold hs-fs-16 bg-light d-flex justify-content-center align-items-center">
            {user?.name?.slice(0, 1)}
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="col-4 float-left">
        <div className="hs-fs-15">{user?.create ? "Created By" : "Edited by"}</div>
        <Text className="hs-fs-17 hs-fw-550">{user?.name ?? ""}</Text>
      </div>
      <div className="col-5">
        <Text className="hs-fs-13">{formattedDate ?? ""}</Text>
      </div>
    </div>
  );
};

const ExtraComponent = ({ content }) => {
  return (
    <>
      {content && (
        <div className="d-flex justify-content-end">
          <span className="hs-fs-11 mt-1">{content}</span>
        </div>
      )}
    </>
  );
};

export const FormInput = (props) => {
  const { instantfocus } = props;
  const inputRef = useRef(null);

  return <HSInput autoFocus={instantfocus} ref={inputRef} autoComplete="off" tabIndex={1} {...props} />;
};

export const RefreshButton = React.memo((props) => {
  const { onClickFunction, loading } = props;
  return <div className={`actionIconsSprite refresh-icon ${loading ? "hs-spin-360-infinite" : ""}`} onClick={onClickFunction} />;
});
export const listAllAutomationRecords = (dataList) => {
  dataList = dataList?.map((obj) => {
    return {
      ...obj,
      eventUrl: (
        <Text
          key={obj?.eventUrl}
          className="d-flex w-100 align-items-center mb-0 hs-color-violet copy-icon-webhook"
          copyable={{
            text: obj?.eventUrl,
            icon: [
              <span key="copy-icon" style={{ textDecoration: "underline" }}>
                Copy Webhook URL
              </span>,
              <CheckOutlined key="copied-icon" />,
            ],
          }}
        />
      ),
      createdTime: formatDate(obj.createdTime),
      key: obj.eventId,
      module: <span className="text-capitalize">{obj?.event?.split("_")?.reverse()?.join(" / ")}</span>,
      fieldApiName: typeof obj?.phoneField === "string" ? obj?.phoneField : JSON.parse(obj?.phoneField),
    };
  });
  return dataList;
};
export const ConstructFieldsList = (fieldResponse) => {
  const filteredFields = fieldResponse?.fields?.filter((field) => field.fieldType === "phone");
  let filterPhoneOptionsArray = [];
  if (filteredFields) {
    filteredFields?.forEach((fieldObj) => {
      const obj = {
        ...fieldObj,
        labelText: fieldObj.fieldName,
        label: <span>{fieldObj.fieldName}</span>,
        value: fieldObj.fieldApiName,
        fieldValue: fieldObj.fieldApiName,
        lookupModuleFieldApiName: fieldObj?.fieldApiName,
      };
      filterPhoneOptionsArray.push(obj);
    });
    if (fieldResponse && fieldResponse?.lookups) {
      fieldResponse?.lookups?.forEach((lookupModulePhoneField) => {
        let filteredLookupFields = lookupModulePhoneField?.fields?.filter((field) => field.fieldType === "phone");

        filteredLookupFields?.forEach((lookupFieldObj) => {
          const obj = {
            ...lookupFieldObj,
            lookupModuleField: lookupModulePhoneField?.fieldName,
            lookupModuleFieldApiName: lookupModulePhoneField?.fieldApiName,
            labelText: lookupFieldObj?.fieldName,
            label: (
              <span>
                {module === lookupModulePhoneField?.moduleName && !lookupModulePhoneField?.lookupModuleField ? (
                  lookupFieldObj?.fieldName
                ) : (
                  <span className="text-capitalize d-flex align-items-center">
                    {lookupModulePhoneField?.fieldName?.length > 10
                      ? lookupModulePhoneField?.fieldName?.slice(0, 9)
                      : lookupModulePhoneField?.fieldName}
                    <span className="actionIconsSprite right-arrow-icon" /> {lookupFieldObj?.fieldName}
                  </span>
                )}
              </span>
            ),
            value: lookupFieldObj?.fieldApiName + "&&" + lookupModulePhoneField?.fieldApiName,
            fieldValue: lookupFieldObj?.fieldApiName + "&&" + lookupModulePhoneField?.fieldApiName,
          };
          filterPhoneOptionsArray.push(obj);
        });
      });
    }
  }
  return filterPhoneOptionsArray;
};
export const handleAddAutomation = (
  valueObj,
  setAddBtnLoading,
  method,
  setDrawerOpen,
  fetchData,
  setValueObj,
  setTableData,
  appProperties,
  setWebhookUrl,
  setWebhookId
) => {
  let notificationMessage = "";

  if (
    valueObj.integId &&
    valueObj.phoneNum &&
    valueObj.fieldApiName &&
    valueObj.templateId &&
    valueObj.friendlyName &&
    valueObj?.integId &&
    valueObj.fieldApiName.length > 0
  ) {
    delete valueObj.eventUrl;
    delete valueObj.key;
    valueObj.fieldApiList = valueObj?.fieldApiName;
    delete valueObj.fieldApiName;
    setAddBtnLoading(true);
    var targetUrl;
    var targetMethod;
    if (method.current === "edit") {
      notificationMessage = "Webhook edited successfully. Review and test the changes.";
      targetUrl = `omessage/${valueObj?.integId}/webhookNotification/${valueObj?.eventId}`;
      targetMethod = "PUT";
    } else {
      notificationMessage = "Webhook configured! Automate messaging with workflow rules.";
      targetUrl = `omessage/${valueObj?.integId}/addWebhook`;
      targetMethod = "POST";
    }
    delete valueObj.createdTime;
    delete valueObj.modifiedTime;
    fetchData(targetUrl, targetMethod, valueObj, appProperties).then(function (response) {
      if (response) {
        successNotification(notificationMessage);
        let data = JSON.parse(response);
        let webhookNotificationEntity = data?.data?.data;

        webhookNotificationEntity = listAllAutomationRecords([webhookNotificationEntity]);

        let eventUrl = data?.data?.data?.eventUrl;

        setWebhookUrl(eventUrl);
        let eventId = data?.data?.data?.eventId;
        setWebhookId(eventId);
        setTableData((prev) => {
          var existingIndex = prev?.findIndex((item) => item?.eventId === valueObj?.eventId);
          if (existingIndex !== -1) {
            const newList = [...prev];
            newList[existingIndex] = webhookNotificationEntity?.[0];
            return newList;
          } else {
            return [...prev, ...webhookNotificationEntity];
          }
        });
        setDrawerOpen(false);
        // setValueObj({});
        setAddBtnLoading(false);
      } else {
        setAddBtnLoading(false);
      }
    });
  } else {
    const fieldsObj = {
      friendlyName: "Friendly name is empty",
      integId: "From number is not valid",
      phoneNum: "From number is not valid",
      fieldApiName: "Selected field is not valid",
      templateId: "Selected template is not valid",
    };
    const fields = Object.keys(fieldsObj);
    const nullField = fields.find((field) => {
      const value = valueObj[field];
      return !value || value?.length < 1;
    });
    if (nullField) {
      failureNotification("Error", `${fieldsObj[nullField]} ! `);
    }
  }
};
export const handleBuyNumberClick = async (appProperties, setAppProperties, fetchData, setBuyNumberLoading, setShowBuyNumber, navigate) => {
  const currentParams = new URLSearchParams(window.location.search);
  const newParams = currentParams.toString();

  let savedNumbers = appProperties?.savedNumbers;
  let helloSendNumbers = savedNumbers?.filter((obj) => obj?.isHelloSendPhoneNumber === "true");
  if (helloSendNumbers?.length > 0) {
    navigate(`/channels/textsms/helloSend?${newParams}`);
  } else {
    if (appProperties.apps) {
      let queryObj = appProperties?.installedApps?.find((app) => app?.right?.service_name === "hello_send");
      if (queryObj) {
        queryObj = {
          integId: queryObj?.integProps?.integId,
          serviceId: queryObj?.right?.service_id,
          isTrialAccount: queryObj?.right?.isTrialAccount,
          rightServiceName: queryObj?.right?.service_name,
          authorized: queryObj?.right?.auth?.authorized,
        };
      }
      let queryObj2 = appProperties?.apps?.SMS?.find((app) => app.name === "hello_send");
      if (queryObj2) {
        queryObj2 = {
          integId: queryObj2?.integId,
          serviceId: queryObj2?.serviceId,
          isTrialAccount: queryObj2?.isTrialAccount,
          rightServiceName: queryObj2?.name,
          authorized: queryObj2?.authorized,
        };
      }
      let rightServiceObj = queryObj ?? queryObj2;
      let integIdNew;
      if (!rightServiceObj?.integId) {
        if (rightServiceObj?.serviceId && appProperties?.leftServiceId) {
          setBuyNumberLoading(true);
          const integrationResp = await fetchData(
            `integration/new?userId=${appProperties?.userId}`,
            "POST",
            {
              left_service_id: appProperties.leftServiceId,
              osync_id: appProperties.osyncId,
              right_service_id: rightServiceObj.serviceId,
            },
            appProperties
          );
          const parsedIntegrationResp = JSON.parse(integrationResp);
          integIdNew = parsedIntegrationResp?.data?.integId;
        }
        let integId = rightServiceObj.integId || integIdNew;
        if (integId && appProperties?.leftServiceId) {
          await fetchData("hellosend/account/" + integId + "/create?leftServiceId=" + appProperties.leftServiceId, "POST", null, appProperties)
            .then(function (response) {
              try {
                if (response) {
                  response = JSON.parse(response);
                  response = response?.data?.integration;
                  setAppProperties((prev) => ({
                    ...prev,
                    serviceInstalled: true,
                    randomIntegId: prev?.randomIntegId ? prev?.randomIntegId : response?.integId,
                    installedApps: [
                      ...(prev?.installedApps || []),
                      {
                        integProps: { ...response },
                        right: {
                          service_display_name: "Hello Send",
                          service_name: "hello_send",
                          auth: {
                            authorized: true,
                            url: true,
                          },
                        },
                      },
                    ],
                  }));
                  setBuyNumberLoading(false);
                  setShowBuyNumber(true);
                }
              } catch (error) {
                console.error("Error processing response:", error);
              }
            })
            .catch((err) => {
              setBuyNumberLoading(false);
            });
        }
      } else {
        setShowBuyNumber(true);
      }
    }
  }
};

export const ConfigurePhone = (props) => {
  const initialValue = {
    primaryPhoneApiName: "",
    primaryPhoneLookUp: "",
    secondaryPhoneApiName: "",
    secondaryPhoneLookUp: "",
  };

  const { setSelectedPhoneFieldApi, setPhoneFieldConfiguration, module, phoneFields, phoneFieldConfiguration, setToNumber, toNumber } = props;
  const [configurePhoneModalOpen, setConfigurePhoneModalOpen] = useState(false);
  const [addBtnLoading, setAddBtnLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [configurePhoneContentLoaded, setConfigurePhoneContentLoaded] = useState(false);
  const [fieldsList, setFieldsList] = useState(null);
  const [fieldsOption, setFieldsOption] = useState([]);
  const [selectedFields, setSelectedFields] = useState(initialValue);
  const [selectedPrimaryValue, setSelectedPrimaryValue] = useState();
  const [selectedSecondaryValue, setSelectedSecondaryValue] = useState();
  const [currentModuleReturnedByApp, setCurrentModuleReturnedByApp] = useState("");
  const { fetchData } = useHttp();
  const { fetchPhoneFieldConfiguration } = useConfigurePhone();
  const [appProperties, setAppProperties] = useContext(AppContext);
  const [selectedIds, setSelectedIds] = useState("");

  const handleModalClose = () => {
    setConfigurePhoneModalOpen(false);
  };

  const phoneConfigurationOptions = useMemo(() => {
    const primarySecondary = [
      {
        label: "Primary phone",
        phoneApiName: "primaryPhoneApiName",
        phoneModule: "primaryPhoneLookUp",
      },
      {
        label: "Secondary phone",
        phoneApiName: "secondaryPhoneApiName",
        phoneModule: "secondaryPhoneLookUp",
      },
    ];

    return primarySecondary;
  }, [appProperties]);

  useEffect(() => {
    if (appProperties?.companyId) {
      appProperties?.controller?.getAssociatedObjectType().then(function (associatedModule) {
        if (associatedModule) {
          setCurrentModuleReturnedByApp(associatedModule);
        }
      });
      appProperties?.controller?.getAssociatedObjectId().then(function (associatedId) {
        if (associatedId) {
          setSelectedIds(associatedId);
        }
      });
    }
    if (module) {
      setCurrentModuleReturnedByApp(module);
      setSelectedFields(initialValue);
    }
  }, [appProperties?.companyId, module]);

  useEffect(() => {
    if (selectedFields) {
      phoneConfigurationOptions.map((obj, index) => {
        const selectedValue =
          selectedFields?.[obj?.phoneApiName] + `${selectedFields?.[obj?.phoneModule] ? `&&${selectedFields?.[obj?.phoneModule]}` : ""}`;
        if (obj?.phoneApiName === "primaryPhoneApiName") {
          setSelectedPrimaryValue(selectedValue);
        } else {
          setSelectedSecondaryValue(selectedValue);
        }
      });
    }
  }, [selectedFields]);

  useEffect(() => {
    if (currentModuleReturnedByApp) {
      setFieldsList(null);
    }
  }, [currentModuleReturnedByApp]);

  useEffect(() => {
    if (phoneFields) {
      setFieldsList(phoneFields);
      setConfigurePhoneContentLoaded(true);
    }
  }, [phoneFields]);
  useEffect(() => {
    if (phoneFieldConfiguration) {
      let phoneFieldEntity = phoneFieldConfiguration?.data?.config;
      if (phoneFieldEntity) {
        setSelectedFields({
          primaryPhoneApiName: phoneFieldEntity?.primaryPhoneApiName ?? "",
          primaryPhoneLookUp: phoneFieldEntity?.primaryPhoneLookUp ?? "",
          secondaryPhoneApiName: phoneFieldEntity?.secondaryPhoneApiName ?? "",
          secondaryPhoneLookUp: phoneFieldEntity?.secondaryPhoneLookUp ?? "",
        });
      }
    }
  }, [phoneFieldConfiguration]);

  useEffect(() => {
    const fetchFields = async () => {
      if (currentModuleReturnedByApp && !phoneFieldConfiguration) {
        appProperties.module = currentModuleReturnedByApp;
        let configuredFields = await fetchPhoneFieldConfiguration(appProperties, "GET");
        if (configuredFields && typeof configuredFields === "string") {
          configuredFields = JSON.parse(configuredFields);
        }
        setPhoneFieldConfiguration(configuredFields);
      }
    };
    if (phoneFields && phoneFields?.length) {
      fetchFields();
    }
  }, [phoneFields]);

  const optionArr = useMemo(() => {
    if (!fieldsList || !currentModuleReturnedByApp) return {};
    return phoneConfigurationOptions.reduce((acc, { phoneApiName }) => {
      const filterValue = phoneApiName === "primaryPhoneApiName" ? selectedSecondaryValue : selectedPrimaryValue;
      acc[phoneApiName] = fieldsList.filter(
        ({ fieldValue, fieldId }) => fieldValue !== filterValue && fieldId !== "Hello-Send-Phone-Configuration-Rule-Id"
      );
      return acc;
    }, {});
  }, [fieldsList, currentModuleReturnedByApp, selectedSecondaryValue, selectedPrimaryValue, phoneConfigurationOptions]);

  useEffect(() => {
    setFieldsOption(optionArr);
  }, [optionArr, setFieldsOption]);

  const addPhoneFields = () => {
    if (appProperties?.randomIntegId && currentModuleReturnedByApp && appProperties.leftServiceId) {
      setAddBtnLoading(true);
      let targetUrl = `${appProperties?.randomIntegId}/modules/phone-fields?leftServiceId=${appProperties.leftServiceId}&resource=${currentModuleReturnedByApp}`;
      if (selectedIds && selectedIds.indexOf(",") === -1) {
        targetUrl += `&selectedIds=${selectedIds}`;
      }

      fetchData(
        targetUrl,
        "POST",
        {
          ...selectedFields,
          moduleName: currentModuleReturnedByApp,
        },
        appProperties
      ).then(function (response) {
        try {
          response = JSON.parse(response);

          if (response?.data?.success) {
            let integDetails = response?.data?.integrationDetails;
            if (integDetails) {
              setAppProperties((prev) => ({
                ...prev,
                phone: integDetails?.phone || "",
              }));
            }
            if (setSelectedPhoneFieldApi) {
              setSelectedPhoneFieldApi(null);
            }
            if (setPhoneFieldConfiguration) {
              setPhoneFieldConfiguration(response);
            }
            handleModalClose();
          } else {
            failureNotification("Error", "Something went wrong !");
          }
          setAddBtnLoading(false);
        } catch (error) {
          setAddBtnLoading(false);
        }
      });
    } else {
      setShowError(true);
    }
  };
  const configurePhoneModalBody = (
    <>
      {configurePhoneContentLoaded ? (
        <Space className="w-100" direction="vertical">
          {phoneConfigurationOptions.map((obj, index) => {
            return (
              <div key={index}>
                <div className="m-1">{obj.label}</div>
                <Select
                  suffixIcon={<div className="actionIconsSprite fromNumberDropDownIcon" />}
                  notFoundContent={<NotFoundContent content="No phone field available." />}
                  value={
                    obj?.phoneApiName === "primaryPhoneApiName"
                      ? selectedPrimaryValue
                      : obj?.phoneApiName === "secondaryPhoneApiName"
                      ? selectedSecondaryValue
                      : null
                  }
                  options={
                    [
                      {
                        label: <span className="opacity-50">- Select -</span>,
                        value: "",
                      },
                      ...((fieldsOption &&
                        fieldsOption[obj?.phoneApiName]?.map((obj, i) => ({
                          label: (
                            <span>
                              {currentModuleReturnedByApp === obj?.moduleName && !obj?.lookupModuleField ? (
                                <span className="text-capitalize d-flex align-items-center">{obj?.fieldName}</span>
                              ) : (
                                <span className="text-capitalize d-flex align-items-center">
                                  {obj?.lookupModuleField?.length > 10 ? obj?.lookupModuleField?.slice(0, 7) : obj?.lookupModuleField}
                                  <span className="actionIconsSprite right-arrow-icon" /> {obj?.fieldName}
                                </span>
                              )}
                            </span>
                          ),
                          value: obj?.fieldValue,
                        }))) ||
                        []),
                    ] || []
                  }
                  onSelect={(e) => {
                    let item = fieldsOption?.[obj?.phoneApiName]?.find((obj) => obj?.fieldValue === e);
                    setSelectedFields((prev) => {
                      const result = {
                        ...prev,
                        [obj?.phoneApiName]: item?.fieldApiName || "",
                        [obj?.phoneModule]: e?.split("&&")?.[1]
                          ? e?.split("&&")?.[1]
                          : currentModuleReturnedByApp === item?.moduleName
                          ? ""
                          : item?.lookupModuleFieldApiName || "",
                      };
                      return result;
                    });
                  }}
                  className={`hs-bg-off-white w-100 rounded focus-border h-44 mt-1 ${showError && index === 0 && `select-field-error`}`}
                  dropdownStyle={{
                    boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.12),0px 20px 20px 0px rgba(0, 0, 0, 0.08)",
                  }}
                  bordered={false}
                  placeholder={`- Select -`}
                  style={{ minWidth: 180, maxWidth: 300 }}
                  onFocus={() => {
                    if (index === 0) {
                      setShowError(false);
                    }
                  }}
                />
              </div>
            );
          })}

          <div className="d-flex justify-content-end">
            <Space>
              <Button className="mt-2 row-reverse d-inline-block hs-btn-cancel-small" onClick={handleModalClose}>
                Cancel
              </Button>
              <Button loading={addBtnLoading} className="hs-btn-small mt-2 row-reverse d-inline-block" onClick={addPhoneFields}>
                Configure
              </Button>
            </Space>
          </div>
        </Space>
      ) : (
        <Space
          direction="vertical"
          size="middle"
          style={{
            display: "flex",
            minWidth: 250,
            minHeight: 200,
          }}
        >
          <div className="w-100 h-100">
            <Spin className="d-flex justify-content-center mt-5 pt-5" indicator={<LoadingOutlined spin />} />
          </div>
        </Space>
      )}
    </>
  );

  return (
    <>
      <span
        onClick={() => {
          setConfigurePhoneModalOpen(true);
        }}
        className="actionIconsSpriteForSideBar configurePhoneSettingsIcon display-inline-block"
      ></span>

      <CommonModal
        modalCloseIcon={true}
        contentSpaceSize="large"
        open={configurePhoneModalOpen}
        doOnClose={handleModalClose}
        header={
          <h6 className="d-flex">
            Configure 'To Phone' for
            <span className="text-capitalize ms-1"> {currentModuleReturnedByApp} module</span>
          </h6>
        }
        body={configurePhoneModalBody}
        okButtonProps={false}
        cancelButtonProps={false}
        width={"auto"}
        styles={{
          mask: { backgroundColor: "rgb(163, 163, 163, 0.2)" },
          content: {
            boxShadow: "rgba(169, 169, 169, 0.366) 0px 13px 61px 0px",
          },
        }}
        centered
      />
    </>
  );
};

export const NotFoundContent = ({ content }) => {
  return (
    <>
      <Empty image={null} description={<span className="empty-content">{content}</span>}></Empty>
      {/* <div className="w-100 m-auto notDataFound d-flex align-items-center justify-content-center">
        {content}
      </div> */}
    </>
  );
};
export const EmptyListView = ({ icon, tagLineOne, tagLineTwo, tagHeight }) => {
  return (
    <>
      <div style={{ height: tagHeight }}>
        <div className="d-flex flex-grow-* flex-column align-items-center justify-content-center h-100">
          <div className={icon}></div>
          <div className="d-flex flex-column align-items-center">
            <span className="hs-fs-16 hs-color-dark-grey mt-1">{tagLineOne}</span>
            <span className="hs-fs-12 hs-text-muted mt-1">{tagLineTwo}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export const CommonModal = (props) => {
  const { okButtonProps, cancelButtonProps, doOnClose, header, modalCloseIcon, body, contentSpaceSize } = props;
  const headerClasses = {
    large: "fs-4 fw-medium",
    small: "fs-6 fw-light",
  };

  return (
    <Modal {...props} onCancel={doOnClose && doOnClose} centered closeIcon={null} mask={false} maskClosable={false} footer={null}>
      <div className="d-flex justify-content-between align-items-start ms-1 mb-1">
        <span className={headerClasses[contentSpaceSize]}>{header}</span>
        {modalCloseIcon && <div onClick={doOnClose && doOnClose} className={"actionIconsSprite mediumSprite closeIconBig ms-2"} />}
      </div>
      <Space size={contentSpaceSize ?? "large"} className="w-100 h-100 modalContent" direction="vertical">
        {body}
        {cancelButtonProps || okButtonProps ? (
          <div className="d-flex justify-content-end pb-3">
            <div className="d-flex align-items-center gap-4">
              {cancelButtonProps && (
                <Button
                  danger={cancelButtonProps?.danger ?? true}
                  {...cancelButtonProps}
                  className={`hs-border-10 big-button hs-fs-16 px-3 ${cancelButtonProps?.className}`}
                >
                  <span className="px-3">{cancelButtonProps?.buttonText}</span>
                </Button>
              )}
              {okButtonProps && (
                <Button
                  {...okButtonProps}
                  className={`hs-bg-violet hs-border-10 big-button text-white hs-fs-16 px-3 ${okButtonProps?.className}`}
                >
                  <span className="px-3">{okButtonProps?.buttonText}</span>
                </Button>
              )}
            </div>
          </div>
        ) : null}
      </Space>
    </Modal>
  );
};

export const DeleteComponent = ({ record, setTableData, appProperties, confirmDeleteComponent, handleDelete, modalOpen, setModalOpen }) => {
  const [loading, setLoading] = useState(false);
  const [outlineColor, setOutlineColor] = useState(false);
  const [deleteText, setDeleteText] = useState("");
  const { fetchData } = useHttp();
  const inputRef = useRef(null);

  const closeModal = () => {
    setModalOpen(false);
    setOutlineColor(false);
    setDeleteText("");
  };

  const handleDeleteClass = (record) => {
    setModalOpen(false);
    handleDelete(record);
  };
  return (
    <>
      <Modal
        className="confirmDeleteModal"
        open={modalOpen}
        onCancel={closeModal}
        width={400}
        centered
        footer={
          <div className="d-flex align-items-center justify-content-between mt-2">
            <Button
              className="w-100  h-45 hs-fs-16 commonGhostButton text-dark"
              onClick={() => {
                setModalOpen(false);
              }}
              style={{ fontWeight: 600 }}
            >
              Cancel
            </Button>
            <Button className="w-100 h-45 confirmDeleteBtn hs-fs-16" onClick={() => handleDeleteClass(record)}>
              Delete
            </Button>
            {loading && <CommonLoadingV2 />}
          </div>
        }
      >
        <Row>
          <Col span={22}>
            <div className="d-flex align-items-center">
              <div className="actionIconsSprite hs-delete-icon me-4"></div>
            </div>
          </Col>
          <Col span={2} onClick={closeModal}>
            <div className="m-2 p-1 pt-2 pe-2">
              <div className="actionIconsSprite closeIconBig me-4"></div>
            </div>
          </Col>
        </Row>
        <div style={{ fontSize: "16px", fontWeight: 600 }} className="mb-1">
          Sure you want to Delete ?
        </div>
        <div className="pb-3">Are you sure you want to delete this {confirmDeleteComponent} ?</div>
      </Modal>
    </>
  );
};

export const removeKeysFromUrlParams = (key) => {
  const url = new URL(window.location.href);

  // Get the search parameters
  const params = new URLSearchParams(url.search);

  if (Array.isArray(key)) {
    key.forEach((keyString) => {
      params.delete(keyString);
    });
  } else {
    params.delete(key);
  }

  // Construct the new URL
  const newUrl = `${url.origin}${url.pathname}?${params.toString()}`;

  // Update the URL in the browser without reloading
  window.history.pushState({}, "", newUrl);
};

export const Logo = React.memo((props) => {
  const { appLogo } = props;
  return (
    <div className="d-flex gap-2">
      <Image className="right-logo" src={appLogo} preview={false} draggable={false} height={45} style={{ width: "auto" }} />
    </div>
  );
});

export const getLabelForTemplate = (friendlyName, templateType, content) => {
  return (
    <Row className="template-item-label">
      <Col span={19}>
        <div className="d-flex gap-2 align-items-center">
          <Text ellipsis>{friendlyName}</Text>
          {templateType && (
            <div className={`staticIconsSprite template-service-indicator-icon template-service-indicator-icon-${templateType}`} />
          )}
        </div>
      </Col>
      <Col span={4} style={{ visibility: "hidden" }} className="savedTemplateEye">
        <div className="d-flex justify-content-end">
          <Tooltip placement={"rightBottom"} title={content}>
            <div className="actionIconsSprite  previewTemplateEyeIcon " />
          </Tooltip>
        </div>
      </Col>
    </Row>
  );
};
